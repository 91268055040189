import React from "react";
import "./App.sass";
import Block from "./block";
import mcu from "./images/mcu.png";
import irreader from "./images/irreader.png";
import influxlogo from "./images/Influxdb_logo.svg";
import com from "./images/com.svg";

function App() {
  return (
    <div className="App">
      <header className="App-header">
        <div className="center-block">
          <h1>Ich lese den Stromzähler aus.</h1>
          <p>Keine Sorge hier wird nichts manipuliert.</p>
        </div>
      </header>
      <div className="container">
        <Block>
          <div>
            <h2>Was ist drin?</h2>
            <p>
              Ein Microcontroller ist mit meinem WiFi verbunden und schickt in
              10 Minuten Intervallen Datenpakete an ein Backend.
            </p>
            <p>
              Der verbaute Microcontroller ist ein ESP8266-07 mit externer
              Antenne für bessere Reichweite (sind ja einige Wände dazwischen).
            </p>
          </div>
          <div
            style={{
              padding: "0 6rem",
            }}
          >
            <img
              src={mcu}
              style={{
                width: "100%",
              }}
            />
          </div>
        </Block>
        <Block>
          <div
            style={{
              padding: "2rem",
            }}
          >
            <img
              src={irreader}
              style={{
                width: "100%",
              }}
            />
          </div>
          <div>
            <h2>Was klebt da am Stromzähler?</h2>
            <p>
              Das ist ein Infrarot(IR)-Lesekopf. Er liest die Daten am
              Strohmzähler aus und sendet sie über die serielle Schnittstelle an
              den Microcontroller.
            </p>
            <p>
              Die Teile sind zum kaufen leider furchtbar teuer. Deshalb habe ich
              selbst eins gebaut (darum die einzelnen Kabel).
            </p>
          </div>
        </Block>
        <Block>
          <div>
            <h2>Und dann?</h2>
            <p>
              Im Backend wird das Datenpaket auseinandergenommen und die
              interessanten Werte extrahiert. In meinem Fall sind das
              Wirkleistung (in Watt) und der aktuelle Zählerstand.
            </p>
            <p>
              Die Daten werden dann zur späteren Visualisierung in einer
              Zeitseriendatenbank gespeichert.
            </p>
          </div>

          <img
            src={influxlogo}
            style={{
              width: "100%",
            }}
          />
        </Block>
        <Block>
          <div
            style={{
              padding: "2rem",
            }}
          >
            <img
              src={com}
              style={{
                width: "100%",
              }}
            />
          </div>
          <div>
            <h2>Noch Fragen?</h2>
            <p>
              Kein Problem. Einfach per Mail an{" "}
              <a href="mailto:flo.giger@gmail.com">flo.giger@gmail.com</a>
            </p>
          </div>
        </Block>
      </div>
    </div>
  );
}

export default App;
