import React from "react";
import "./Block.sass";

export default function Block(props: any) {
  return (
    <div className="block-container">
      <div className="col-6">{props.children[0]}</div>
      <div className="col-6">{props.children[1]}</div>
    </div>
  );
}
